import React, { useState } from "react";
import { Table, Pagination } from "antd";
import useWindowSize from "./useWindow";

const App = ({ columnnn, dataaa, isLoadinggg,isInventory }) => {
  const { height, width } = useWindowSize();

  // Calculate available height for the table body
  const tableHeight = height - 300; // Adjust this value based on your layout
  const [ setPagination] = useState({
    current: 1,
    pageSize: 200,
    total: 0,
  });
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const getRowClassName = (record) => {
    // Ensure record and quantities exist
    if (!record?.quantity || !record?.product?.buffer_stock?.quantity || !isInventory) {
      return "";
    }
  
    const bufferQuantity = record.product.buffer_stock.quantity;
    const orderedQuantity = record.quantity[0]?.value;
  
    if (orderedQuantity !== undefined) {
      if (bufferQuantity >= orderedQuantity) {
        console.log(bufferQuantity, orderedQuantity);
        return "out-of-stock";
      } else {
        return "bg-primary";
      }
    }
  
    return "";
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 230px)",
      }}
    >
      <div style={{ flexGrow: 1, overflow: "auto" }}>
        <Table
          sticky
          size="large"
          style={{ height: tableHeight + "px" }}
          columns={columnnn}
          dataSource={dataaa}
          scroll={{ y: tableHeight, x: width }}
          loading={isLoadinggg}
          pagination={false}
          rowClassName={getRowClassName}
          onChange={handleTableChange}
        />
      </div>
      <div style={{ flexShrink: 0 }}>
        <Pagination
          style={{ textAlign: "center", padding: "10px 0" }}
          pageSize={10}
          pageSizeOptions={["10", "20", "30", "40"]}
          total={dataaa?.length} // Dynamically get the total from the data source
          showSizeChanger
        />
      </div>
    </div>
  );
};

export default App;
