
import {getDashboardAnalyticsData} from "../../store/inventoryReducer"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Card, Row, Col, Statistic } from "antd";






const App = () => {

  const { statData } = useSelector((state) => state.inventory);
const dispatch = useDispatch()
  
  // for graph



  useEffect(()=>{
    dispatch(getDashboardAnalyticsData())
  },[dispatch])


  
  //
  return (
    <div>
      {/* <Header className="text-xl pt-2" style={{ background: "#fff" }}>
          Welcome To Marna
        </Header> */}
      <Row gutter={16}>
        <Col span={8}>
          <Card>
            <Statistic title="Users" value={statData?.userCount} />
          </Card>
          <Card>
            <Statistic title="Products" value={statData?.productCount} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              className=""
              title=" Inventory "
              value={statData?.inventoryCount}
            />
          </Card>
          <Card>
            <Statistic
              title="Customers "
              className=""
              value={statData?.customerCount}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Warehouses"
              value={statData?.warehouseCount}
            />
            {/* <label>Cup</label> */}
          </Card>
          <Card>
            <Statistic
              title="Product Categories"
              value={statData?.categoryCount}
            />
          </Card>
        </Col>
      </Row>
      {/* <Card style={{ marginTop: "16px" }}>
        <Table columns={columns} dataSource={inventoryData} />
      </Card> */}
    </div>
  );
};

export default App;
