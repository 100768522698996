import { Row, Col, Button, Form, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useState, React, useEffect } from "react";
import { createNewCustomer } from "../../store/customerReducers";
import { useParams } from "react-router-dom";

const UserReg = () => {

  const { useRRr } = useSelector((state) => state.user);

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone_no, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getWarehouseReducer());
  // }, []);

  const { id } = useParams();
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const [form] = Form.useForm();
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 116,
    },
  };

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        setName(useRRr?.name);
        setAddress(useRRr?.address);
        setPhone(useRRr?.phone_no);
        setEmail(useRRr?.email);
        setCompany(useRRr?.company);
        // setWarehouseName(useRRr?.warehouse_name);
      }, 400);
    }
  }, [id,useRRr]);

  const handleName = (ev) => {
    setName(ev.target.value);
  };

  const updateCustomer = ()=>{

  }
  const handleCompany = (event) => {
    setCompany(event.target.value);
  };
  const handleAddress = (ev) => {
    setAddress(ev.target.value);
  };
  const handlePhone = (event) => {
    if (event.target.value.length > 9) {
    } else {
      setPhone(event.target.value);
    }
  };

 

  const handleEmail = (ev) => {
    setEmail(ev.target.value);
  };


 
  const handleFormSubmit = async () => {
    dispatch(
      createNewCustomer({
        name,
        company,
        address,
        phone_no,
        email,
      })
    );
  };

  const onReset = () => {
    form.resetFields();
    setName("");
    setCompany("");
    setAddress("");
    setEmail("");
    setPhone("");
  };

  return (
    <>
      <h2 className="text-xl flex justify-center pt-2 mb-2 ">
        {id ? "Update Customer Detail" : "Register New Customer"}
      </h2>

      <Row gutter={4} className="mr-10 flex justify-center">
        
        <Col span={8}>
          <Form
            {...layout}
            form={form}
            layout="vertical"
            name="control-hooks"
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              label="Full Name"
              rules={[
                {
                  required: true,
                  message: "Full Name is required",
                },
              ]}
            >
              <Input
                name="name"
                placeholder="Full Name"
                value={name}
                onChange={handleName}
              />
            </Form.Item>
            <Form.Item
              label="Phone No"
              rules={[
                {
                  required: true,
                },
                {
                  type: Number,
                },
              ]}
            >
              <Input
                addonBefore="+251"
                placeholder="Phone Number"
                type="number"
                name="phone"
                value={phone_no}
                onChange={handlePhone}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              rules={[
                {
                  required: true,
                },
                {
                  type: "email",
                },
              ]}
            >
              <Input
                addonBefore={<MailOutlined style={{ width: "20px" }} />}
                type="email"
                name="email"
                value={email}
                onChange={handleEmail}
                placeholder="name@example.com"
              />
            </Form.Item>
            </Form>
        </Col>
        <Col span={8}>
          <Form
            {...layout}
            form={form}
            layout="vertical"
            name="control-hooks"
            style={{
              maxWidth: 600,
            }}
          >
            
            

            <Form.Item
              label="Company"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                name={company}
                value={company}
                size="large"
                placeholder="Company Name"
                // style={{ height: "40px" }}

                onChange={handleCompany}
              />
               
            </Form.Item>
            <Form.Item
              label="Address"
            >
              <Input
                name={address}
                value={address}
                size="large"
                placeholder="Address"
                // style={{ height: "40px" }}

                onChange={handleAddress}
              />
               
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                className="mr-4"
                htmlType="button"
                onClick={() => onReset()}
                style={{marginRight:"10px"}}
              >
                Reset
              </Button>
              <Button
              style={{backgroundColor: "#5856d6", color: "white"}}
                htmlType="submit"
                onClick={id ? updateCustomer : handleFormSubmit}
              >
                {id ? "Update Customer Data" : "Register Customer"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default UserReg;
