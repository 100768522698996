import { logoutInternal } from "../../store/loginReducer";
import { Row, Col, Badge, Dropdown, Button, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { LogoutOutlined } from "@ant-design/icons";
import NotificationBage from "../notificationBage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getOutOfStockProducts } from "../../store/inventoryReducer";

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

const handleLogout = () => {};

function Header({ onPress }) {
  const { lowStockProducts } = useSelector((state) => state.inventory);
  const profile = (
    <Menu style={{ marginTop: "10px" }}>
      <Menu.Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );
  //
  const navigate = useHistory();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutInternal());
  };

  useEffect(() => {
    dispatch(getOutOfStockProducts());
  }, [dispatch]);

  //

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <div></div>
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title "
              style={{ textTransform: "capitalize" }}
            >
              Ethioder
            </span>
          </div>
        </Col>
        <Col
          span={24}
          md={18}
          style={{ alignContent: "center" }}
          className="header-control"
        >
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
          <Badge size="small">
            <Dropdown overlay={profile} trigger={["click"]}>
              <a
                href="#pablo"
                className="ant-dropdown-link"
                style={{
                  marginLeft: "14px",
                }}
                onClick={logout}
              >
                <Button icon={<LogoutOutlined />} />
              </a>
            </Dropdown>
          </Badge>
          <div
            onClick={() =>
              navigate.push("/inventoryInAllWarehouse?low-stock=low")
            }
          >
            <NotificationBage count={lowStockProducts?.length} />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Header;
