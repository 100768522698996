import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../components/tables/table";
import { useEffect, useState } from "react";
import { getCategoryReducerForActive } from "../../store/categoryReducers";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import {
  getAllTransactionReducer,
  getDailyTransactionReducer,
  getRangeTransactionReducer,
} from "../../store/transactionReducers";
// import { ConvertDate } from "../../utils/moment";
import { ConvertDate } from "../../utils/moment";
import { Row, Select, Col, Input, Button } from "antd";
import { toast } from "react-toastify";
const { Option } = Select;
const ProductReport = () => {
  const dispatch = useDispatch();
  const { transactions } = useSelector((state) => state.transaction);
  const { categories } = useSelector((state) => state.category);
  const { warehouses } = useSelector((state) => state.warehouse);
  const { crdentialDataForOne } = useSelector((state) => state.login);
  useEffect(() => {
    dispatch(getActiveWarehouseReducer());
    dispatch(getCategoryReducerForActive());

    dispatch(getAllTransactionReducer());
  }, [dispatch]);

  const [transactionType, setTransactionType] = useState("");
  const [warehose, setWarehose] = useState("");
  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [daily, setDaily] = useState("daily");
  const [isDisabled, setIsDisabled] = useState(true);

  const handleTransactionType = (event) => {
    setTransactionType(event);
  };

  const handleTransactionCategory = (event) => {
    setCategory(event);
  };
  const handleTransactionWarehouse = (event) => {
    setWarehose(event);
  };

  const handleTransactionTime = (event) => {
    setDaily(event);
    if (event === "daily") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const handleTransactionSTartDate = (event) => {
    setStartDate(event.target.value);
    //setIsDisabled(false);

    // dispatch(
    //   getDailyTransactionReducer({
    //     date: startDate,
    //     category,
    //     warehouse: warehose,
    //     type: transactionType,
    //   })
    // );
  };
  const handleTransactionEndDate = (event) => {
    //setIsDisabled(false);
    setEndDate(event.target.value);
  };

  const handleSearchTransaction = () => {
    if (startDate || endDate) {
      if (daily === "daily") {
        console.log("Transaction Type", transactionType);
        setIsDisabled(true);
        dispatch(
          getDailyTransactionReducer({
            date: startDate,
            category,
            warehouse: warehose,
            type: transactionType,
          })
        );
      } else {
        //   setIsDisabled(false);
        dispatch(
          getRangeTransactionReducer({
            start_date: startDate,
            end_date: endDate,
            category,
            warehose:
              crdentialDataForOne.role === "admin"
                ? warehose
                : crdentialDataForOne.warehouse,
            type: transactionType,
          })
        );
      }
    } else {
      toast.error("please select the transaction date you want to see first");
    }
  };

  const columns = [
    {
      title: "Transaction Date",
      width: "20%",
      render: (text, record) => <span>{ConvertDate(record?.createdAt)}</span>,
    },
    {
      title: "Warehouse",
      render: (text, record) => (
        <span>{record.inventory?.warehouse?.name}</span>
      ),
    },
    
    {
      title: "Product",
      width:'15%',
      render: (text, record) => <span>{record.inventory?.product?.name}</span>,
    },
    {
      title: "Transaction By",
      width:'15%',
      render: (text, record) => (
        <span>{record?.user?.first_name + " " + record?.user?.last_name}</span>
      ),
    },
    {
      title: "Transaction Type",
      render: (text, record) => <span>{record.transaction_type}</span>,
    },
    {
      title: "Ref",
      render: (text, record) => <span>{record.gin}</span>,
    },
    {
      title: "Taken By",
      render: (text, record) => <span>{record.taken_by ? record.taken_by : "Empty"}</span>,
    },
    
    {
      title: "Quantity",
      width: "20%",
      render: (text, record) => (
        <>
          {record.quantity?.map((item) => (
            <ul className="flex flex-row">
              <li className="mr-2 capitalize" style={{textTransform:"capitalize"}}>{item.name} : </li>{" "}
              <li className="font-bold">{item.value}</li>
            </ul>
          ))}
        </>
      ),
    },
  ];

  return (
    <div>
      <p className="text-xl pt-2 pb-2"> Transaction Range</p>
      <div className="mb-2">
        <Row gutter={20} className="pl-3">
          <Col xs={24} sm={24} lg={6}>
            <Select
              onChange={handleTransactionType}
              className="w-full "
              defaultValue={"All Transactions"}
              options={[
                //   { value: "", label: "Select Transaction Type", disabled: true },
                { value: "", label: "All Transactions" },
                {
                  name: "Incoming",
                  value: "Incoming",
                  label: "Incoming Transactions",
                },
                { value: "Outgoing", label: "Outcoming Transactions" },
              ]}
            ></Select>
          </Col>
          <Col xs={24} sm={24} lg={6}>
            <Select
              onChange={handleTransactionCategory}
              className="w-full "
              defaultValue={"All Category"}
            >
              <option value="">All Category</option>
              {categories?.map((item, key) => (
                <Option key={key} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={24} lg={6} className="">
            <div className="flex flex-row w-full">
              <Input
                type="date"
                value={startDate}
                onChange={handleTransactionSTartDate}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} lg={6} className="">
            <Select
              className="w-full"
              value={daily}
              onChange={handleTransactionTime}
              placeholder="Select Date "
              defaultValue="daily"
            >
              <option value="daily">Daily</option>
              <option value="range">Range</option>
            </Select>
          </Col>
        </Row>
        <Row gutter={20} className="pl-3">
          <Col xs={24} sm={24} lg={6}>
            <Select
              onChange={handleTransactionWarehouse}
              className="w-full "
              defaultValue={"All Warehouse"}
            >
              <option value="">All Warehouse</option>
              {warehouses?.map((item, key) => (
                <option key={key} value={item._id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={24} lg={6}>
            <div>
              <Input placeholder="Search by Product" />
            </div>
          </Col>
          <Col xs={24} sm={24} lg={6}>
            <Input
              className="flex flex-row w-full"
              type="date"
              value={endDate}
              disabled={isDisabled}
              onChange={handleTransactionEndDate}
            />
          </Col>
          <Col xs={24} sm={24} lg={6}>
            <Button
              className="flex flex-row w-full"
              onClick={handleSearchTransaction}
            >
              Filter Transaction
            </Button>
          </Col>
        </Row>
      </div>
      <CustomTable columnnn={columns} dataaa={transactions} />
    </div>
  );
};
export default ProductReport;
