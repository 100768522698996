import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Select,
  Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ConvertDate } from "../../utils/moment";
import {
  createInventoryreducer,
  getProductsSearchReducer,
} from "../../store/inventoryReducer";
import { getAllCustomers } from "../../store/customerReducers";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import {
  createOutgoingList,
  fetchOutgoingLists,
} from "../../store/outgoingListReducers";
import TableA from "../../components/tables/table";
import { toast } from "react-toastify";
import {
  useHistory,
} from "react-router-dom";
const Incominginventory = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    //
  };
  const navigate = useHistory();
  const [quantityData, setQuantityData] = useState([]);
  const [productQuantity, setProductQuantity] = useState([]);
  const [productId, setProductId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [fsNumber, setFSNumber] = useState("");
  const [gin, setgin] = useState("");
  const [remarks, setRemarks] = useState("");
  const [productQuery] = useState("");
  const [warehouseName, setWarhousename] = useState("");
  const { inventories } = useSelector((state) => state.inventory);
  const [inventorystatus, setInventoryStatus] = useState("");
  const [activeTab] = useState("draft");
  const [handleModal, setHandleModal] = useState("");
  const [openIncomingModal, setOpenIncomingModal] = useState(false);
  const { outgoingLists } = useSelector((state) => state.outgoing);
  const { crdentialDataForOne } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const { warehouses } = useSelector((state) => state.warehouse);
  const { customers } = useSelector((state) => state.customer);
  const [customer, setCustomer] = useState("");
  const [singleCustomerDetail, setSingleCustomerDetail] = useState({});
  const [customerDetailModal, setOPenCustomerDetailModal] = useState(false);
  useEffect(() => {
    dispatch(fetchOutgoingLists());
    dispatch(getActiveWarehouseReducer());
    dispatch(getAllCustomers());
    setQuantityData([]);
    setWarhousename(crdentialDataForOne?.warehouse);
  }, [dispatch,crdentialDataForOne]);

  const handleProductQuery = (event) => {
    dispatch(
      getProductsSearchReducer({
        query: event.target.value,
      })
    );
  };
  
  const handleFsNumber = (event) => {
    setFSNumber(event.target.value);
  };
  const displayIncomingModal = () => {
    setOpenIncomingModal(true);
  };
  
  const addNewIncomingList = async () => {
    try {
      // Dispatch the createOutgoingList action and wait for it to complete
      const resultAction = await dispatch(
        createOutgoingList({
          warehouse: warehouseName,
          remarks: {
            content: remarks,
            remark_by: localStorage.getItem("userId"),
          },
          fs_number: fsNumber,
          customer,
          gin: gin,
        })
      );
  
      // Check if the action was fulfilled (successful)
      if (createOutgoingList.fulfilled.match(resultAction)) {
        // Fetch the updated list after successfully creating the new list
        await dispatch(fetchOutgoingLists());
        // Optionally, you can reset any local state or close modals here
      } else {
        console.error('Failed to create the outgoing list:', resultAction.payload);
        // Handle the error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error('Error creating outgoing list:', error);
      // Handle any additional errors here (e.g., show an error message)
    }
  };
  const openCustomerDetail = (customer) => {
    setSingleCustomerDetail(customer);
    setOPenCustomerDetailModal(true);
  };
  const handlegin = (evt) => {
    setgin(evt.target.value);
  };
  const addIncomingInventory = () => {
    const arr = Object.entries(quantityData).map(([key, value]) => ({
      name: key,
      value,
    }));
    dispatch(
      createInventoryreducer({
        warehouse: crdentialDataForOne?.warehouse,
        product: productId,
        remarks: {
          content: remarks,
          remark_by: localStorage.getItem("userId"),
        },
        quantity: arr,
        grn: gin,
      })
    );
    setOpenModal(false);
  };
  const showHelp = () => {
    //
  };

  const handleRemarks = (event) => {
    setRemarks(event.target.value);
  };
  const handleWareHouse = (event) => {
    setWarhousename(event);
  };

  const displayModal = (record, handleModal) => {
    setHandleModal(handleModal);
    if (handleModal === "OutgoingInventory") {
      if (warehouseName === "") {
        toast("Please select warehouse");
      } else {
        setProductQuantity(record?.quantity);
        setProductId(record?._id);
        setOpenModal(true);
        setInventoryStatus("incoming");
      }
    } else {
      setOpenModal(true);
    }
  };

  const handleQuantityData = (evnt) => {
    evnt.persist();
    setQuantityData({
      ...quantityData,
      [evnt.target.name]: evnt.target.value,
    });
  };
  const handleCustomer = (event) => {
    setCustomer(event);
  };
  const columnsForDraftIncoming = [
    {
      title: "GIN",
      width: "10%",
      render: (text, record) => <span>{record.gin}</span>,
    },
    {
      title: "Status",
      width: "10%",
      render: (text, record) => (
        <span style={{ textTransform: "capitalize" }}>{record.status}</span>
      ),
    },
    {
      title: "Customer",
      render: (text, record) => (
        <span
          style={{
            cursor: "pointer",
            textTransform: "capitalize",
            textDecoration: "underline",
            color: "ActiveBorder",
          }}
          title="Click to see detail of the customer"
          onClick={() => openCustomerDetail(record?.customer)}
        >
          {record.customer?.name}
        </span>
      ),
    },
    {
      title: "Created By",
      width: "10%",
      render: (text, record) => (
        <span style={{ textTransform: "capitalize" }}>
          {record.created_by?.first_name + " " + record.created_by?.last_name}
        </span>
      ),
    },
    {
      title: "Created At",
      width: "10%",
      render: (text, record) => <span>{ConvertDate(record.createdAt)}</span>,
    },
    {
      title: "Remarks",
      width: "15%",
      render: (text, record) => (
        <span>
          {record.remarks.map((item)=>(
            <div style={{marginBottom:"2px",padding:"4px"}}>
              <li style={{fontWeight:"bolder",textTransform:"capitalize"}}>{item.remark_by?.first_name + " "+ item.remark_by?.last_name}</li>
              <li>{item.content}</li>
            </div>

          ))}
        </span>
      ),
      ellipsis: true,
    },
    {
      title: "Warehouse",
      width: "10%",
      render: (text, record) => <span>{record.warehouse.name}</span>,
    },
    {
      title: "Actions",
      width: "15%",
      render: (text, record) => (
        <span>
          <Button
            title="add product to this list"
            onClick={() => {
              navigate.push("/sales/outgoing/" + record._id);
            }}
            className="mr-2"
            icon={<PlusCircleOutlined />}
          ></Button>
        </span>
      ),
    },
  ];

  const columnsA = [
    {
      title: "Product Name",
      width: "10%",
      render: (text, record) => <span>{record.name}</span>,
    },
    {
      title: "Product Description",
      width: "15%",
      render: (text, record) => (
        <span>
          {record.description}
          <br />
        </span>
      ),
      ellipsis: true,
    },

    {
      title: "Quantity",
      width: "35%",
      render: (text, record) => (
        <span>
          {record.quantity}
          <br />
        </span>
      ),
    },
    {
      title: "Actions",
      width: "15%",
      render: (text, record) => (
        <span>
          <Button
            onClick={() => displayModal(record, "OutgoingInventory")}
            className="mr-2"
            icon={<PlusCircleOutlined />}
          ></Button>
        </span>
      ),
    },
  ];

  return (
    <div className="ml-4">
      <Modal
        centered
        onOk={inventorystatus === "incoming" ? addIncomingInventory : showHelp}
        onCancel={() => setOpenModal(false)}
        title={
          inventorystatus === "incoming"
            ? "Incoming Inventory"
            : "How to import Excel"
        }
        visible={openModal}
      >
        {handleModal === "OutgoingInventory" ? (
          <Row gutter={5}>
            <Col xs={24} lg={24}>
              <Form
                layout="vertical"
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                style={{
                  width: 300,
                }}
              >
                {productQuantity?.map((item) => (
                  <Form.Item
                    className="capitalize"
                    style={{ textTransform: "capitalize" }}
                    name={item}
                    label={item}
                  >
                    <Input
                      name={item}
                      value={item}
                      onChange={handleQuantityData}
                    />
                  </Form.Item>
                ))}

                <Form.Item
                  className="capitalize"
                  name="remarks"
                  label="Remarks"
                >
                  <Input
                    type="text"
                    name={remarks}
                    value={remarks}
                    onChange={handleRemarks}
                  />
                </Form.Item>

                <Form.Item className="capitalize" name="gin" label="Ref./GRN/">
                  <Input name="gin" value={gin} onChange={handlegin} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        ) : (
          <div>
            <p>
              1. first you must check the column names in the product
              specification and the excel column names you want to import
            </p>
            <p>2. you must import only one excel sheet at a time</p>
          </div>
        )}
      </Modal>

      <Modal
        centered
        onOk={() => setOPenCustomerDetailModal(false)}
        onCancel={() => setOPenCustomerDetailModal(false)}
        title="Customer detail info"
        visible={customerDetailModal}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10",
            justifyContent: "center",
          }}
        >
          <div className="">Full Name: <span style={{fontWeight:"bolder",marginLeft:"4px",textTransform:"capitalize"}}>{singleCustomerDetail?.name}</span> </div>
          <div className="">Email: <span style={{fontWeight:"bolder",marginLeft:"4px",textTransform:"capitalize"}}>{singleCustomerDetail?.email}</span></div>
          <div className="">Phone: <span style={{fontWeight:"bolder",marginLeft:"4px",textTransform:"capitalize"}}>{singleCustomerDetail?.phone_no}</span></div>
          <div className="">Company: <span style={{fontWeight:"bolder",marginLeft:"4px",textTransform:"capitalize"}}>{singleCustomerDetail?.company}</span></div>
          <div className="">Address: <span style={{fontWeight:"bolder",marginLeft:"4px",textTransform:"capitalize"}}>{singleCustomerDetail?.address}</span></div>
        </div>
      </Modal>
      <Modal
        centered
        onOk={addNewIncomingList}
        onCancel={() => setOpenIncomingModal(false)}
        title="New Outgoing List"
        visible={openIncomingModal}
      >
        <Row>
          <Col xs={24} lg={24}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item label="Select Warehouse">
                    <Select
                      size="large"
                      className="w-full mr-2"
                      onChange={handleWareHouse}
                      placeholder="Select warehouse"
                    >
                      {warehouses?.map((item) => (
                        <option value={item._id} selected>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={24} xl={12}>
                  <Form.Item label="Select Customer">
                    <Select
                      size="large"
                      showSearch
                      onChange={handleCustomer}
                      style={{
                        width: 200,
                      }}
                      placeholder="Search Customers"
                      optionFilterProp="label"
                      filterOption={(input, option) => {
                        const customer = customers.find(
                          (c) => c._id === option.value
                        );
                        return (
                          customer &&
                          (customer?.name
                            ?.toLowerCase()
                            .includes(input.toLowerCase()) ||
                            customer?.email
                              ?.toLowerCase()
                              .includes(input.toLowerCase()) || // Add other fields you want to search by
                            customer?.phone
                              ?.toLowerCase()
                              .includes(input.toLowerCase())) // Example field
                        );
                      }}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                    >
                      {customers?.map((item) => (
                        <option value={item._id}>{item.name}</option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                className="capitalize"
                name="fsNumber"
                label="Fs Number"
              >
                <Input
                  type="text"
                  name={fsNumber}
                  value={fsNumber}
                  onChange={handleFsNumber}
                />
              </Form.Item>
              <Form.Item className="capitalize" name="remarks" label="Remarks">
                <Input
                  type="text"
                  name={remarks}
                  value={remarks}
                  onChange={handleRemarks}
                />
              </Form.Item>

              <Form.Item className="capitalize" name="gin" label="GIN/">
                <Input name="gin" value={gin} onChange={handlegin} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      {activeTab === "draft" ? (
        <div className="table">
          <h2 className="text-xl mb-2">Outgoing Lists</h2>
          <Row style={{ marginBottom: "5px" }}>
            <Col xs={24} sm={24} lg={8}></Col>
            <Col xs={24} sm={24} lg={8}></Col>

            <Col
              xs={24}
              sm={24}
              lg={8}
              style={{
                float: "right",
                display: "flex",
                justifyContent: "right",
              }}
            >
              {crdentialDataForOne?.role === "sales" ? (
                <Button
                  style={{ backgroundColor: "#5856d6", color: "white" }}
                  onClick={displayIncomingModal}
                >
                  Add New Outgoing List
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <TableA columnnn={columnsForDraftIncoming} dataaa={outgoingLists} />
        </div>
      ) : (
        <div>
          <h2 className="text-xl mb-2">Incoming Inventory</h2>
          <Row gutter={20}>
            <Col xs={24} sm={24} lg={8}>
              <Form
                layout="vertical"
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item name="productname" label="Product Name">
                  <Input
                    value={productQuery}
                    name="productQuery"
                    onChange={handleProductQuery}
                    placeholder="Search by Product"
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <div>
            <TableA columnnn={columnsA} dataaa={inventories} />
          </div>
        </div>
      )}
    </div>
  );
};
export default Incominginventory;
