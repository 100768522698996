import { Menu } from "antd";
import {
  DashboardFilled,
  FileOutlined,
  PieChartOutlined,
  BranchesOutlined,
  BellFilled,
  UserAddOutlined,
  PlusCircleOutlined,
  BankOutlined,
  ShopOutlined,
  StockOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/ethioder_logo.jpg";
import { useSelector } from "react-redux";



function Sidenav({ color }) {
  //
  const {crdentialDataForOne } = useSelector(
    (state) => state.login
  );
  //
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  

  const adminRole = [
    {
      holder: "Inventory System",
      lists: [
        {
          key: 1,
          icon: <DashboardFilled />,
          title: "Dashboard",
          to: "/dashboard",
        },

        {
          key: 3,
          icon: <UserAddOutlined />,
          to: "/userRegistration",
          title: "User Registration",
        },
        {
          key: 4,
          icon: <PlusCircleOutlined />,
          to: "/viewUser",
          title: "View Users",
        },
        
        {
          key: 6,
          icon: <FileOutlined />,
          to: "/viewProduct",
          title: "View Products",
        },

        {
          key: 7,
          icon: <BankOutlined />,
          to: "/addWarehouse",
          title: "Add Warehouse",
        },
        {
          key: 8,
          icon: <BellFilled />,
          to: "/viewWarehouse",
          title: "View Warehouse",
        },
        {
          key: 10,
          icon: <ShopOutlined />,
          to: "/viewCategory",
          title: "View Category",
        },

        {
          key: 13,
          icon: <StockOutlined />,
          to: "/inventoryInAllWarehouse",
          title: "Inventory",
        },
        {
          key: 14,
          icon: <StockOutlined />,
          to: "/transactionRange",
          title: "Transaction",
        },
      ],
    },
  ];
  ///
  const warehouseAdminRole = [
    {
      holder: "Inventory System",
      lists: [
        {
          key: 1,
          icon: <DashboardFilled />,
          title: "Dashboard",
          to: "/dashboard",
        },
        //
        {
          key: 5,
          icon: <BranchesOutlined />,
          to: "/addProduct",
          title: "Add Product",
        },
        {
          key: 6,
          icon: <FileOutlined />,
          to: "/viewProduct",
          title: "View Products",
        },

        {
          key: 8,
          icon: <BellFilled />,
          to: "/viewWarehouse",
          title: "View Warehouse",
        },
        {
          key: 9,
          icon: <PieChartOutlined />,
          to: "/addCategory",
          title: "Add Category",
        },
        {
          key: 10,
          icon: <ShopOutlined />,
          to: "/viewCategory",
          title: "View Category",
        },
        {
          key: 2,
          icon: <PlusCircleOutlined />,
          to: "/incomingInventory",
          title: "Incoming Inventory",
        },
        {
          key: 11,
          icon: <MinusCircleOutlined />,
          to: "/sales/outgoing",
          title: "Outgoing Inventory",
        },
        {
          key: 13,
          icon: <StockOutlined />,
          to: "/inventoryInAllWarehouse",
          title: "Inventory",
        },
        {
          key: 14,
          icon: <StockOutlined />,
          to: "/transactionRange",
          title: "Transaction",
        },
      ],
    },
  ];



  const sales = [
    {
      holder: "Inventory System",
      lists: [
        {
          key: 1,
          icon: <DashboardFilled />,
          title: "Dashboard",
          to: "/dashboard",
        },

        {
          key: 1,
          icon: <DashboardFilled />,
          title: "View Customers",
          to: "/customers/list",
        },

        {
          key: 1,
          icon: <DashboardFilled />,
          title: "Add Customer",
          to: "/customer/add",
        },
        //

        
        {
          key: 11,
          icon: <MinusCircleOutlined />,
          to: "/sales/outgoing",
          title: "Outgoing Inventory",
        },
      ],
    },
  ];

  const finance = [
    {
      holder: "Inventory System",
      lists: [
        {
          key: 1,
          icon: <DashboardFilled />,
          title: "Dashboard",
          to: "/dashboard",
        },
        //

        {
          key: 6,
          icon: <FileOutlined />,
          to: "/viewProduct",
          title: "View Products",
        },
        {
          key: 2,
          icon: <PlusCircleOutlined />,
          to: "/pendingIncoming",
          title: "Incoming Inventory",
        },
        {
          key: 11,
          icon: <MinusCircleOutlined />,
          to: "/sales/outgoing",
          title: "Pending Approval",
        },
        

        

        {
          key: 13,
          icon: <StockOutlined />,
          to: "/inventoryInAllWarehouse",
          title: "Inventory",
        },
        {
          key: 14,
          icon: <StockOutlined />,
          to: "/transactionRange",
          title: "Transaction",
        },
      ],
    },
  ];
  
  return (
    <>
      <div style={{ width: "100%" }}>
        <img src={logo} alt="" />
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {crdentialDataForOne.role === "admin" &&
          adminRole.map((item) => (
            <span>
              <Menu.Item className="menu-item-header" key="5">
                {item.holder}
              </Menu.Item>
              {item.lists.map((list) => (
                <Menu.Item key={list.key}>
                  <NavLink to={list.to}>
                    <span
                      className="icon"
                      style={{
                        background: page === "profile" ? color : "",
                      }}
                    >
                      {list.icon}
                    </span>
                    <span className="label">{list.title}</span>
                  </NavLink>
                </Menu.Item>
              ))}
            </span>
          ))}
       

        {crdentialDataForOne.role === "warehouseAdmin" &&
          warehouseAdminRole.map((item) => (
            <span>
              <Menu.Item className="menu-item-header" key="5">
                {item.holder}
              </Menu.Item>
              {item.lists.map((list) => (
                <Menu.Item key={list.key}>
                  <NavLink to={list.to}>
                    <span
                      className="icon"
                      style={{
                        background: page === "profile" ? color : "",
                      }}
                    >
                      {list.icon}
                    </span>
                    <span className="label">{list.title}</span>
                  </NavLink>
                </Menu.Item>
              ))}
            </span>
          ))}

{crdentialDataForOne.role === "finance" &&
          finance.map((item) => (
            <span>
              <Menu.Item className="menu-item-header" key="5">
                {item.holder}
              </Menu.Item>
              {item.lists.map((list) => (
                <Menu.Item key={list.key}>
                  <NavLink to={list.to}>
                    <span
                      className="icon"
                      style={{
                        background: page === "profile" ? color : "",
                      }}
                    >
                      {list.icon}
                    </span>
                    <span className="label">{list.title}</span>
                  </NavLink>
                </Menu.Item>
              ))}
            </span>
          ))}

        {crdentialDataForOne.role === "sales" &&
          sales.map((item) => (
            <span>
              <Menu.Item className="menu-item-header" key="5">
                {item.holder}
              </Menu.Item>
              {item.lists.map((list) => (
                <Menu.Item key={list.key}>
                  <NavLink to={list.to}>
                    <span
                      className="icon"
                      style={{
                        background: page === "profile" ? color : "",
                      }}
                    >
                      {list.icon}
                    </span>
                    <span className="label">{list.title}</span>
                  </NavLink>
                </Menu.Item>
              ))}
            </span>
          ))}
      </Menu>
      {/* <Menu
        theme="dark"
        defaultSelectedKeys={["1"]}
        onClick={({ key }) => {
          navigate.push(key);
        }}
        mode="inline"
        items={items}
      /> */}
      <div className="aside-footer">{new Date().getFullYear()} Powered by Andinet ICT Solution.</div>
    </>
  );
}

export default Sidenav;
