import axios from "../plugins/axios";

export const loginUsr = async (cridentials) => {
  const response = await axios.post("/api/users/login", cridentials);
  if(response){
    localStorage.setItem("userId",response.data._id)
  }
  return response;
};

export const logoutUsr = async () => {
  const response = await axios.get("api/users/logout");
  return response;
};
