import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
createCustomer,getCustomers
} from "../services/userAPI";
import { toast } from "react-toastify";

const intialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,
  customer: {},
  customers: [],
};

export const createNewCustomer = createAsyncThunk(
  "customer/create",
  async (userData, thunkApi) => {
    try {
      return await createCustomer(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const getAllCustomers = createAsyncThunk(
  "customer/get",
  async ( thunkApi) => {
    try {
      return await getCustomers();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
//






const customerSlice = createSlice({
  name: "customer",
  initialState: intialState,
  reducers: {
    
  },

  extraReducers: (builder) => {
    builder
      .addCase(createNewCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.useRRr = action.payload;
        toast.success("Customer Registered Successfully!");
      })
      .addCase(createNewCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getAllCustomers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.customers = action.payload?.data;
      })
      .addCase(getAllCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      
  },
});
export const isSuccess = (state) => state.customer.isSuccess;
export const isError = (state) => state.customer.isError;
export const isLoading = (state) => state.customer.isLoading;
export const customer = (state) => state.customer.customer;
export const customers = (state) => state.customer.users;
export const { updateUserDataReducer } = customerSlice.actions;
export default customerSlice.reducer;
