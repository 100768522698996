import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../../pages/dashbord/dashbord";
import Main from "../../components/layout/Main";
import ViewCategory from "../../pages/category/viewCategory"
import AddCategory from "../../pages/category/addCategory"
import ViewProduct from "../../pages/product/viewProduct"
import AddProduct from "../../pages/product/addProduct"
import AddWarehouse from "../../pages/warehouse/addWarehouse"
import ViewWarehouse from "../../pages/warehouse/viewWarehouse"
import ViewUsers from "../../pages/Users/viewUsers"
import UserRegistration from "../../pages/Users/userRegistration"
import IncomingInventory from "../../pages/inventory/viewInventory"
import OutgoingInventory from "../../pages/inventory/viewInventoryForOutgoing";
import StockInventory from "../../pages/inventory/inventoryInAllStock"
import SingleIncomingList from "../../pages/inventory/singleInventory"
import TransactionRange from "../../pages/report/transactionRange"
import PendingIncoming from "../../pages/finance/pendingIncomingLists"
import PendingSingleIncoming from "../../pages/finance/singleIncomingLists"

import PendingSingleOutgoingSales from "../../pages/sales/singleIncomingLists.jsx"
import PendingOutgoingSales from "../../pages/sales/pendingIncomingLists"
import AddCustomer from "../../pages/sales/customerRegistration.jsx"
import ViewCustomer from "../../pages/sales/viewCustomers.jsx"
//
import "antd/dist/antd.css";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
import "../../pages/category/viewCategory"

function App() {
    
    return (
        <div className="App">
            <Switch>
                <Main>
                    <Route exact path="/userRegistration" component={UserRegistration} />
                    <Route exact path="/userRegistration/:id" component={UserRegistration} />
                    <Route exact path="/viewUser" component={ViewUsers} />
                    <Route exact path="/addCategory" component={AddCategory} />
                    <Route exact path="/addCategory/:id" component={AddCategory} />
                    <Route exact path="/viewCategory" component={ViewCategory} />
                    <Route exact path="/viewProduct" component={ViewProduct} />
                    <Route exact path="/addProduct" component={AddProduct} />
                    <Route exact path="/singleIncomingList/:id" component={SingleIncomingList} />
                    <Route exact path="/addProduct/:id" component={AddProduct} />
                    <Route exact path="/addWarehouse" component={AddWarehouse} />
                    <Route exact path="/addWarehouse/:id" component={AddWarehouse} />
                    <Route exact path="/viewWarehouse" component={ViewWarehouse} />
                    <Route exact path="/pendingIncoming" component={PendingIncoming} />
                    <Route exact path="/pendingIncoming/:id" component={PendingSingleIncoming} />


                    <Route exact path="/sales/outgoing" component={PendingOutgoingSales} />
                    <Route exact path="/sales/outgoing/:id" component={PendingSingleOutgoingSales} />

                    <Route exact path="/customers/list" component={ViewCustomer} />
                    <Route exact path="/customer/add" component={AddCustomer} />

                    <Route exact path="/incomingInventory" component={IncomingInventory} />
                    <Route exact path="/outGoingInventory" component={OutgoingInventory} />
                    <Route exact path="/transactionRange" component={TransactionRange} />
                    <Route exact path="/inventoryInAllWarehouse" component={StockInventory} />
                    <Route exact path="/dashboard" component={Home} />

                    <Redirect from="*" to="/dashboard" />
                </Main>
            </Switch>
        </div>
    );
}

export default App;