import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createUser,
  getUser,
  getActiveUsers,
  getRemovedUsers,
  updateUser,
  deleteUser,
  undoUser,
  resetPassword,
} from "../services/userAPI";
import { toast } from "react-toastify";

const intialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,
  useRRr: {},
  users: [],
};

export const createUserReducer = createAsyncThunk(
  "user/create",
  async (userData, thunkApi) => {
    try {
      return await createUser(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const getuserReducer = createAsyncThunk(
  "user/get",
  async (pageNumber, thunkApi) => {
    try {
      console.log("get user reducersss", pageNumber);
      return await getUser(pageNumber);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log("getUser reducers in case of Error", pageNumber);
      return thunkApi.rejectWithValue(message);
    }
  }
);
//
export const getuserReducerForActive = createAsyncThunk(
  "user/get",
  async (pageNumber, thunkApi) => {
    try {
      return await getActiveUsers();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log("getUser reducers in case of Error");
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getuserReducerForRemoved = createAsyncThunk(
  "remove/get",
  async (pageNumber, thunkApi) => {
    try {
      return await getRemovedUsers(pageNumber);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
//

export const updateUserReducer = createAsyncThunk(
  "user/update",
  async (payload, thunkApi) => {
    try {
      console.log("Updatettt", payload.id);
      return await updateUser(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const deleteUSerReducer = createAsyncThunk(
  "delet/user",
  async (para, thinkApi) => {
    try {
      console.log("In reducerRRRRRR " + para.id);
      return await deleteUser(para);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thinkApi.rejectWithValue(message);
    }
  }
);

export const undoUserReducer = createAsyncThunk(
  "undo/user",
  async (para, thinkApi) => {
    try {
      return await undoUser(para);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thinkApi.rejectWithValue(message);
    }
  }
);

export const resetPasswordReducer = createAsyncThunk(
  "reset/user",
  async (para, thinkApi) => {
    try {
      return await resetPassword(para);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thinkApi.rejectWithValue(message);
    }
  }
);

const userSlice = createSlice({
  name: "staff",
  initialState: intialState,
  reducers: {
    getAllUsers: (state) => {
      //  state.users.filter
    },
    updateUserDataReducer: (state, action) => {
      state.useRRr = action.payload;
    },
  },

  extraReducers: (parammm) => {
    parammm
      .addCase(createUserReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createUserReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.useRRr = action.payload;
        toast.success("User Registered Successfully!");
      })
      .addCase(createUserReducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getuserReducerForActive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getuserReducerForActive.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.users = action.payload?.data?.users;
      })
      .addCase(getuserReducerForActive.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(getuserReducerForRemoved.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getuserReducerForRemoved.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.users = action.payload?.data?.users;
      })
      .addCase(getuserReducerForRemoved.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(updateUserReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserReducer.fulfilled, (state, action) => {
        state.isError = false;
        state.isSuccess = true;
        state.isLoading = false;
        //state.users = action.payload;
        //console.log("Update User" + action.payload?.data);
        toast.success("User Updated succefully");
      })
      .addCase(updateUserReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(deleteUSerReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUSerReducer.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        console.log("KKKKK", action.payload);
        const record1 = action.payload;
        state.users = state.users?.filter(
          (record) => record._id !== record1.data?.user?._id
        );
        // let haf = state.users.filter(
        //   (user) => user._id != action.payload.data.user._id
        // );
        toast.success("Staff member Deleted Successfullyyyyy");
      })
      .addCase(deleteUSerReducer.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(undoUserReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(undoUserReducer.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        const record1 = action.payload;
        state.users = state.users?.filter(
          (record) => record._id !== record1.data?.user?._id
        );
        toast.success("Staff member Undo Successfully");
      })
      .addCase(undoUserReducer.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(resetPasswordReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPasswordReducer.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        toast.success("Password Reseted Successfully");
      })
      .addCase(resetPasswordReducer.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
        toast.error(action.payload);
      });
  },
});
export const isSuccess = (state) => state.user.isSuccess;
export const isError = (state) => state.user.isError;
export const isLoading = (state) => state.staff.isLoading;
export const useRRr = (state) => state.staff.useRRr;
export const users = (state) => state.user.users;
export const { updateUserDataReducer } = userSlice.actions;
export default userSlice.reducer;
