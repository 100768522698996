import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Select,
  Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ConvertDate } from "../../utils/moment";
import {
  createInventoryreducer,
  getProductsSearchReducer,
} from "../../store/inventoryReducer";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import {
  createIncomingList,
  fetchIncomingLists,
} from "../../store/incomingListReducer";
import TableA from "../../components/tables/table";
import { toast } from "react-toastify";
import {
  
  useHistory,
} from "react-router-dom";
const Incominginventory = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    //
  };
  const navigate = useHistory();
  const [quantityData, setQuantityData] = useState([]);
  const [productQuantity, setProductQuantity] = useState([]);
  const [productId, setProductId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [gin, setgin] = useState("");
  const [remarksContent, setRemarks] = useState("");
  const [productQuery] = useState("");
  const [warehouseName, setWarhousename] = useState("");
  const { inventories } = useSelector((state) => state.inventory);
  const [inventorystatus, setInventoryStatus] = useState("");
  const [activeTab] = useState("draft");
  const [handleModal, setHandleModal] = useState("");
  const [openIncomingModal, setOpenIncomingModal] = useState(false);
  const { incomingLists } = useSelector((state) => state.incoming);
  const { crdentialDataForOne } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchIncomingLists());
    dispatch(getActiveWarehouseReducer());
    setQuantityData([]);
    setWarhousename(crdentialDataForOne?.warehouse);
  }, [dispatch,setWarhousename,crdentialDataForOne]);

  const handleProductQuery = (event) => {
    dispatch(
      getProductsSearchReducer({
        query: event.target.value,
      })
    );
  };
  
  const displayIncomingModal = () => {
    setOpenIncomingModal(true);
  };
  
  

  const addNewIncomingList = async () => {
    try {
      // Wait for the createIncomingList action to complete
      const resultAction = await dispatch(
        createIncomingList({
          warehouse: crdentialDataForOne?.warehouse,
          remarks: {
            content: remarksContent,
            remark_by: localStorage.getItem("userId"),
          },
          grn: gin,
        })
      );
  
      // Check if the action was fulfilled (successful)
      if (createIncomingList.fulfilled.match(resultAction)) {
        // Fetch the updated list after successfully creating the new list
        dispatch(fetchIncomingLists());
      } else {
        console.error('Failed to create the incoming list:', resultAction.payload);
      }
    } catch (error) {
      console.error('Error creating incoming list:', error);
    }
  };

 
  const handlegin = (evt) => {
    setgin(evt.target.value);
  };
  const addIncomingInventory = () => {
    const arr = Object.entries(quantityData).map(([key, value]) => ({
      name: key,
      value,
    }));
    dispatch(
      createInventoryreducer({
        warehouse: crdentialDataForOne?.warehouse,
        product: productId,
        remarks: {
          content: remarksContent,
          remark_by: localStorage.getItem("userId"),
        },
        quantity: arr,
        grn: gin,
      })
    );
    setOpenModal(false);
  };
  const showHelp = () => {
    //
  };

  const handleRemarks = (event) => {
    setRemarks(event.target.value);
  };

  const displayModal = (record, handleModal) => {
    setHandleModal(handleModal);
    if (handleModal === "OutgoingInventory") {
      if (warehouseName === "") {
        toast("Please select warehouse");
      } else {
        setProductQuantity(record?.quantity);
        setProductId(record?._id);
        setOpenModal(true);
        setInventoryStatus("incoming");
      }
    } else {
      setOpenModal(true);
    }
  };
const handleFilterStatus = (event)=>{

}
  const handleQuantityData = (evnt) => {
    evnt.persist();
    setQuantityData({
      ...quantityData,
      [evnt.target.name]: evnt.target.value,
    });
  };

  const columnsForDraftIncoming = [
    {
      title: "GRN",
      width: "10%",
      render: (text, record) => <span>{record.grn}</span>,
    },
    {
      title: "Status",
      width: "10%",
      render: (text, record) => (
        <span style={{ textTransform: "capitalize" }}>{record.status}</span>
      ),
    },
    {
      title: "Created By",
      width: "10%",
      render: (text, record) => (
        <span style={{ textTransform: "capitalize" }}>
          {record.created_by?.first_name + " " + record.created_by?.last_name}
        </span>
      ),
    },
    {
      title: "Created At",
      width: "10%",
      render: (text, record) => <span>{ConvertDate(record.createdAt)}</span>,
    },
    {
      title: "Remarks",
      width: "15%",
      render: (text, record) => (
        <span>
          {record.remarks.map((item)=>(
            <div style={{marginBottom:"2px",border:"1px solid gray",padding:"4px"}}>
              <li style={{fontWeight:"bolder"}}>{item.remark_by?.first_name + " "+ item.remark_by?.last_name}</li>
              <li>{item.content}</li>
            </div>

          ))}
        </span>
      ),
      ellipsis: true,
    },
    {
      title: "Warehouse",
      width: "10%",
      render: (text, record) => <span>{record.warehouse.name}</span>,
    },
    {
      title: "Actions",
      width: "15%",
      render: (text, record) => (
        <span>
          <Button
            title="add product to this list"
            onClick={() => {
              navigate.push("singleIncomingList/" + record._id);
            }}
            className="mr-2"
            icon={<PlusCircleOutlined />}
          ></Button>
        </span>
      ),
    },
  ];

  const columnsA = [
    {
      title: "Product Name",
      width: "10%",
      render: (text, record) => <span>{record.name}</span>,
    },
    {
      title: "Product Description",
      width: "15%",
      render: (text, record) => (
        <span>
          {record.description}
          <br />
        </span>
      ),
      ellipsis: true,
    },

    {
      title: "Quantity",
      width: "35%",
      render: (text, record) => (
        <span>
          {record.quantity}
          <br />
        </span>
      ),
    },
    {
      title: "Actions",
      width: "15%",
      render: (text, record) => (
        <span>
          <Button
            onClick={() => displayModal(record, "OutgoingInventory")}
            className="mr-2"
            icon={<PlusCircleOutlined />}
          ></Button>
        </span>
      ),
    },
  ];

  return (
    <div className="ml-4">
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "right",
          marginBottom: "10px",
          alignItems: "center",
          gap:"5px"
        }}
      >
        <Select
        onChange={handleFilterStatus}
          defaultValue="draft"
          size="medium"
          style={{
            width: 160,
          }}
          options={[
            {
              value: "draft",
              label: "Draft",
            },
            {
              value: "pending_approval",
              label: "Pending Approval",
            },
            {
              value: "approved",
              label: "Approved",
            }
          ]}
        />
        {activeTab === "draft" ? (
          <Button
            onClick={displayIncomingModal}
            color="green"
            style={{ backgroundColor: "#5856d6", color: "white" }}
          >
            Add New Incoming Lists
          </Button>
        ) : (
          ""
        )}
      </div>
      <Modal
        centered
        onOk={inventorystatus === "incoming" ? addIncomingInventory : showHelp}
        onCancel={() => setOpenModal(false)}
        title={
          inventorystatus === "incoming"
            ? "Incoming Inventory"
            : "How to import Excel"
        }
        visible={openModal}
      >
        {handleModal === "OutgoingInventory" ? (
          <Row gutter={5}>
            <Col xs={24} lg={24}>
              <Form
                layout="vertical"
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                style={{
                  width: 300,
                }}
              >
                {productQuantity?.map((item) => (
                  <Form.Item
                    className="capitalize"
                    style={{ textTransform: "capitalize" }}
                    name={item}
                    label={item}
                  >
                    <Input
                      name={item}
                      value={item}
                      onChange={handleQuantityData}
                    />
                  </Form.Item>
                ))}

                <Form.Item
                  className="capitalize"
                  name="remarks"
                  label="Remarks"
                >
                  <Input
                    type="text"
                    name={remarksContent}
                    value={remarksContent}
                    onChange={handleRemarks}
                  />
                </Form.Item>

                <Form.Item className="capitalize" name="gin" label="Ref./GRN/">
                  <Input name="gin" value={gin} onChange={handlegin} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        ) : (
          <div>
            <p>
              1. first you must check the column names in the product
              specification and the excel column names you want to import
            </p>
            <p>2. you must import only one excel sheet at a time</p>
          </div>
        )}
      </Modal>

      <Modal
        centered
        onOk={addNewIncomingList}
        onCancel={() => setOpenIncomingModal(false)}
        title="New Incoming Lists"
        visible={openIncomingModal}
      >
        <Row gutter={5}>
          <Col xs={24} lg={24}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                width: 300,
              }}
            >
              {/* <Form.Item
                className="capitalize"
                name="displayName"
                label="Remarks"
              >
                <Input
                  type="text"
                  name={displayName}
                  value={displayName}
                  onChange={handleDisplayName}
                />
              </Form.Item> */}
              <Form.Item className="capitalize" name="remarks" label="Remarks">
                <Input
                  type="text"
                  name={remarksContent}
                  value={remarksContent}
                  onChange={handleRemarks}
                />
              </Form.Item>

              <Form.Item className="capitalize" name="gin" label="Ref./GRN/">
                <Input name="gin" value={gin} onChange={handlegin} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      {activeTab === "draft" ? (
        <div className="table">
          <TableA columnnn={columnsForDraftIncoming} dataaa={incomingLists} />
        </div>
      ) : (
        <div>
          <p className="text-xl mb-2">Incoming Inventory</p>
          <Row gutter={20}>
            <Col xs={24} sm={24} lg={8}>
              <Form
                layout="vertical"
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item name="productname" label="Product Name">
                  <Input
                    value={productQuery}
                    name="productQuery"
                    onChange={handleProductQuery}
                    placeholder="Search by Product"
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <div>
            <TableA columnnn={columnsA} dataaa={inventories} />
          </div>
        </div>
      )}
    </div>
  );
};
export default Incominginventory;
