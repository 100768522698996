import axios from "../plugins/axios";

export const createProduct = async (productData) => {
  const respord = await axios.post("/api/products", productData);
  return respord;
};
export const getProduct = async (productdata) => {
  const respose = await axios.get("/api/products", productdata);
  return respose;
};

export const getRemovedProduct = async (productdata) => {
  const respose = await axios.get("/api/products/removed/all", productdata);
  return respose;
};

export const getActiveProduct = async (productdata) => {
  const respose = await axios.get("/api/products", productdata);
  return respose;
};

export const getSearchProduct = async (productdata) => {
  const respose = await axios.get("/api/products/search", productdata);
  return respose;
};

export const updateProduct = async (payload) => {
  console.log("lLLLL" + payload.id);
  const responce = await axios.patch("/api/products/" + payload.id, payload);
  return responce;
};

export const deleteProduct = async (payload) => {
  const responce = await axios.delete("/api/products/" + payload.id, payload);
  return responce;
};

export const activateProduct = async (payload) => {
  const responce = await axios.patch(
    "/api/products/activate/" + payload.id,
    payload
  );
  return responce;
};

export const addProductImage = async (payload) => {
  const formData = FormData();
  formData.append("image", payload.image);
  const response = await axios.post(
    "/api/one/product/" + payload.productId,
    formData
  );
  return response;
};
