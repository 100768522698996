import axios from "../plugins/axios";

export const createIncomingInventory = async (incomingInvData) => {
  const response = await axios.post("/api/inventory/incoming", incomingInvData);
  return response;
};

export const getIncomingInventoryOnly = async () => {
  const responss = await axios.get("/api/inventory/incoming");
  return responss;
};

export const getInventorysearch = async (payload) => {
  const response = await axios.post(
    `/api/inventory/search?warehouseId=${payload.warehouseId}&categoryId=${payload.categoryId}&productQuery=${payload.productQuery}`
  );
  console.log("OutGoing Inventory", response);
  return response;
};

export const getProductSearch = async (payload) => {
  const response = await axios.post("/api/products/search", payload);
  return response;
};

export const createOutGoingInventory = async (outgoingData) => {
  const responce = await axios.post("/api/inventory/outgoing", outgoingData);
  return responce;
};

export const getOutGoingInventory = async () => {
  const respon = await axios.post("/api/inventory");
  return respon;
};

export const getInventory = async () => {
  const respon = await axios.get("/api/inventory");
  return respon;
};

export const getAllInventoryInStock = async () => {
  const respon = await axios.post("/api/inventory");
  return respon;
};

export const outOfStockInventory = async()=>{
  const response = await axios.get("/api/inventory/stock/low");
  return response;
}

export const getAnalyticsData = async()=>{
  const response = await axios.get("/api/stats/count");
  return response;
}