import React from 'react';
import { Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';
const NotificationIcon = ({ count }) => {

  return (
    <Badge   size='large' style={{marginRight:"5px",marginLeft:"5px",cursor:'pointer'}} count={count} overflowCount={99}>
      <BellOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
    </Badge>
  );
};

export default NotificationIcon;