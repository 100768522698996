import {
  Input,
  Row,
  Col,
  Form,
  Button,
  Modal,
} from "antd";
import TableAl from "../../components/tables/table";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllCustomers
} from "../../store/customerReducers";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
const ViewAl = () => {
  const navigate = useHistory();
  const { customers, isLoading } = useSelector((state) => state.customer);
  const { crdentialDataForOne } = useSelector((state) => state.login);
  const [userId] = useState([]);

  // const [actions, setActions] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    // setActions(true);
    dispatch(getAllCustomers()); /// this must be changed to active users
  }, [dispatch]);





  const colummmWarehouseAdmin = [
    {
      title: "Full name",
      dataIndex: "name",
      key: "1",
    },
    {
      title: "Company name",
      dataIndex: "company",
      key: "2",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "3",
    },

    {
      title: "Phone Number",
      key: "4",
      render:(text,record)=>{
        return <span>{record.phone_no?.map((item)=>(<li>{'+251'+item}</li>))}</span>
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "5",
    },
  ];

  const colummmAdmin = [
    {
      title: "Full name",
      dataIndex: "name",
      key: "1",
    },
    {
      title: "Company name",
      dataIndex: "company",
      key: "2",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "3",
    },

    {
      title: "Phone Number",
      dataIndex: "phone_no",
      render:(text,record)=>{
        return <span>{record.phone_no?.map((item)=>(<li>{'+251' + item}</li>))}</span>
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "5",
    },
    // {
    //   title: "Actions",
    //   render: (text, record) => (
    //     <span>
    //       {actions && (
    //         <Tooltip title="Edit Customer">
    //           <Button
    //             style={{ marginRight: "5px" }}
    //             icon={<EditOutlined />}
    //           ></Button>
    //         </Tooltip>

    //       )}
    //       {actions && (
    //         <Tooltip title="Delete Customer">
    //           <Button
    //             style={{ marginRight: "5px" }}
    //             icon={<DeleteOutlined />}
    //           ></Button>
    //         </Tooltip>
    //       )}

    //     </span>
    //   ),
    // },
  ];

  return (
    <div>
      <input type="text" value={userId} hidden />
      <div>
        <Modal
          centered
          onCancel={() => setOpenModal(false)}
          title="Reset Password"
          visible={openModal}
        >
          
        </Modal>
      </div>
      <div>
        <h2 className="text-xl mb-2">List of Customers</h2>
      </div>
      <div>
        <Form
          name="complex-form"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          className="mb-2"
        >
          <div>
            {crdentialDataForOne?.role === "admin" ? (
              <Row gutter={20}>
                <Col xs={24} sm={24} lg={8}>
                  <Input className="ml-2 " placeholder="Search" />
                </Col>
                
                <Col xs={24} sm={24} lg={8}>
                  <Button
                  style={{backgroundColor: "#5856d6", color: "white"}}
                    onClick={() => navigate.push("/userRegistration")}
                    className="justify-end bg-blue-300"
                  >
                    Add New Customer
                  </Button>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
        </Form>
      </div>
      <TableAl
        columnnn={
          crdentialDataForOne.role === "sales"
            ? colummmAdmin
            : colummmWarehouseAdmin
        }
        isLoadinggg={isLoading}
        dataaa={customers}
      />
    </div>
  );
};
export default ViewAl;
