import React, { useEffect, useState } from "react";
import {
  Image,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  getInventoryInAllStockReducer,
  getOutOfStockProducts,
} from "../../store/inventoryReducer";

import TableA from "../../components/tables/table";
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};


const InventoryInAllStock = () => {
  const query = useQuery();
  const lowStock = query.get('low-stock');
  const { allInventries,lowStockProducts } = useSelector((state) => state.inventory);
  const dispatch = useDispatch();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const Image_url = "https://ethiodererp.com/images/";
  useEffect(() => {
    if (lowStock) {
      dispatch(getOutOfStockProducts());
    } else {
      dispatch(getInventoryInAllStockReducer());
    }
  }, [dispatch,lowStock]);

  

  let dataSource = [];
  // setTimeout(() => {
  dataSource = Object.keys(allInventries).map((key) => ({
    key,
    ...allInventries[key],
  }));
  // }, 300);
  const handlePreview = (imageUrl) => {
    setPreviewImage(imageUrl);
    setPreviewOpen(true);
  };

  const columnsA = [
    {
      title: "Product Name",
      width: "10%",
      render: (text, record) => <span>{record?.product?.name}</span>,
    },

    {
      title: "Product Category",
      width: "10%",
      render: (text, record) => <span>{record?.productCategory?.name}</span>,
    },
    {
      title: "Product Code",
      width: "10%",
      render: (text, record) => <span>{record?.product?.code}</span>,
    },
    {
      title: "Product Image",
      width: "10%",
      render: (text, record) => (
        <span>
          {record.product?.image_extension ? (
            <Image
              width={100}
              src={
                Image_url +
                record.product?._id +
                record.product?.image_extension
              }
              onClick={() =>
                handlePreview(
                  Image_url +
                    record.product?._id +
                    record.product?.image_extension
                )
              }
              style={{ cursor: "pointer" }}
              preview={{
                visible: previewOpen,
                src: previewImage,
                onVisibleChange: (visible) => setPreviewOpen(visible),
              }}
            />
          ) : (
            <p>Image unavailable</p>
          )}
        </span>
      ),
    },
    {
      title: "Quantity",
      width: "10%",
      render: (text, record) => (
        <span>
          {record.quantity?.map((item) => (
            <li style={{ textTransform: "capitalize" }}>
              {item.name}: <b>{item.value}</b>
            </li>
          ))}
        </span>
      ),
    },
    {
      title: "Buffer Stock",
      width: "10%",
      render: (text, record) => (
        <span>{record?.product?.buffer_stock?.quantity}</span>
      ),
    },
  ];
  return (
    <div className="ml-10">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginRight: "20px",
        }}
      >
        <h2 className="text-xl mb-4">All Inventory</h2>
        {lowStockProducts?.length > 0 ? <p style={{color:"red"}}>{lowStockProducts?.length} products are out of stock</p>: ''}
      </div>
      <div>
        <div>
          <TableA columnnn={columnsA} dataaa={dataSource} isInventory={true} />
        </div>
      </div>
    </div>
  );
};
export default InventoryInAllStock;
