import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import { createLoginReducer } from "../store/loginReducer";

import { LockOutlined, MailOutlined } from "@ant-design/icons";

import { useHistory } from "react-router-dom";
import marna_logo from "../assets/images/ethioder_logo.jpg";

import { Button, Layout, Col, Form, Input, Row } from "antd";
import { toast } from "react-toastify";

const { Footer, Content } = Layout;

const onFinishFailed = (errorInfo) => {
};
const onFinish = (values) => {
};
const App = () => {
  const navigate = useHistory();
  const [formData, setFormData] = useState({
    email: "", // this can change to phone no
    password: "",
  });
  const handleUserandPassword = (evn) => {
    setFormData({
      ...formData,
      [evn.target.name]: evn.target.value,
    });
  };

  const { isLoading } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const handleLogin = async () => {
    if (!formData.email || !formData.password) {
      return toast.info("Email and password are required!");
    }
    dispatch(createLoginReducer(formData));
    navigate.push("/dashbord");
  };

  return (
    <Layout>
      <Content>
        <div style={{ margin: "20px" }}>
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 150px)",
            }}
            gutter={32}
          >
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 10, offset: 2 }}
              md={{ span: 10 }}
            >
              <h1
                className=""
                style={{
                  color: "#1E90FF",
                }}
              >
                Login to account
              </h1>

              <Form
                name="basic"
                layout="vertical"
                className="row-col"
                wrapperCol={{
                  span: 15,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  className="mt-5 no-star"
                  label="Email Address"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    name="email"
                    value={formData.email}
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="name@example.com"
                    onChange={handleUserandPassword}
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    name="password"
                    value={formData.password}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    onChange={handleUserandPassword}
                    placeholder="password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                  onClick={handleLogin}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      width:"100%",
                      background:"#1e90ff",
                      justifyContent:'center',
                      color:'#fff'
                    }}
                  >
                    {isLoading ? <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "10px",color:'white', }} // Adds space between spinner and button text
                      width="24px"
                      height="24px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <circle
                        cx="50"
                        cy="50"
                        r="32"
                        strokeWidth="8"
                        stroke="#fff"
                        strokeDasharray="50.26548245743669 50.26548245743669"
                        fill="none"
                        strokeLinecap="round"
                      >
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          repeatCount="indefinite"
                          dur="1s"
                          keyTimes="0;1"
                          values="0 50 50;360 50 50"
                        />
                      </circle>
                    </svg>
                     : ''} {isLoading ? 'Loading...' : "Login"}
                  </Button>
                </Form.Item>
              </Form>
            </Col>

            <Col xs={{ span: 0 }} lg={{ span: 12 }} md={{ span: 12 }}>
              <span style={{ pointerEvents: "none" }}>
                <img
                  src={marna_logo}
                  alt="Marna_logo"
                  style={{ maxWidth: "350px", maxHeight: "300px" }}
                ></img>
              </span>
            </Col>
          </Row>
        </div>
      </Content>

      <Footer
        style={{ position: "fixed", bottom: 0, width: "100%", height: "50px" }}
      >
        <p className="copyright">
          {" "}
          Powered by <a href="#pablo">Andinet ICT Solution</a> ©{" "}
          {new Date().getFullYear()}
        </p>
      </Footer>
    </Layout>
  );
};
export default App;
