import { configureStore } from "@reduxjs/toolkit";


import categoryReducer from "./categoryReducers"
import loginReducer from "./loginReducer"
import userReducerr from "./userReducer"
import inventoryReducer from "./inventoryReducer"
import productReducer from "./productReducer"
import wareHouseReducer from "./warehouseReducer"
import transactionReducer from "./transactionReducers"
import incomingLists from "./incomingListReducer"
import outgoingList from "./outgoingListReducers"
import customerReducers from "./customerReducers"
export const store = configureStore({
  reducer: {
    user: userReducerr,
    login: loginReducer,
    category: categoryReducer,
    inventory: inventoryReducer,
    product: productReducer,
    warehouse: wareHouseReducer,
    transaction: transactionReducer,
    incoming: incomingLists,
    outgoing: outgoingList,
    customer: customerReducers,

  },
});

