import axios from "../plugins/axios";

export const createUser = async (userData) => {
  const responceBackend = await axios.post("/api/users/register", userData); // "/adduser" is the backend APi to add users
  return responceBackend;
};
export const getUser = async (pageNumber) => {
  const responceBackend = await axios.get("/api/users/getUser");
  return responceBackend;
};

export const getActiveUsers = async () => {
  const responceBackend = await axios.get("/api/users");
  return responceBackend;
};
export const getRemovedUsers = async (pageNumber) => {
  const responceBackend = await axios.get("/api/users/removed/all");
  return responceBackend;
};

export const updateUser = async (payload) => {
  const response = await axios.patch(
    "/api/users/updateuser/" + payload.id,
    payload
  );
  return response;
};

export const deleteUser = async (payload) => {
  const response = await axios.delete("/api/users/remove/" + payload.id);
  return response;
};

export const undoUser = async (payload) => {
  const response = await axios.put("/api/users/activate/" + payload.id);
  return response;
};

export const resetPassword = async (payload) => {
  const response = await axios.put("/api/users/resetpassword", payload);
  return response;
};


/// for customers

export const createCustomer = async (customerData) => {
  const responceBackend = await axios.post("/api/customers/create", customerData); // "/adduser" is the backend APi to add users
  return responceBackend;
};
export const getCustomers = async () => {
  const responceBackend = await axios.get("/api/customers/all");
  return responceBackend;
};